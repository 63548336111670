import tableState from "../../templates/table/state";

const customizedTableState = tableState();
let date = new Date();
customizedTableState.filters.from = formatDate(
  new Date(new Date().setDate(date.getDate() - 180))
);

function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;

  return [year, month].join("-");
}
export default {
  ...customizedTableState
};
