import tableGetters from "../../templates/table/getters";
import { isNil } from "lodash";

export default {
  ...tableGetters,
  visitorsCountsHeaders: state => {
    return !isNil(state.entity[state.ids[0]])
      ? state.entity[state.ids[0]].months.map(month => {
          return {
            text: month.date,
            value: month.date,
            sortable: !(state.entity[state.ids[0]].months.length > 1),
            class: "table-header-wvr"
          };
        })
      : [];
  }
};
